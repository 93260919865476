import { BRAND_WOWCHER } from '../setup/setup';

const Environment = process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER;

const cookies = {
  abAutocomplete: 'addr-auto-test',
  abBookingCalendarOnRedemptionGift: 'bc-gifting-test',
  affiliate: 'dod-affiliate',
  affiliateUser: 'dod-affiliate-returned',
  apiAuthToken: 'apiAuthToken',
  awin_event: 'awin_event',
  awinSource: 'dod-awin-source',
  basketToken: 'bt',
  brandCookie: `${Environment}-win`,
  brandswapTest: 'brandswap-test',
  buyDeal: 'dod-buy-deal',
  cjevent: 'cjevent',
  comp: 'comp-test',
  confirmEmailRequired: 'cer',
  core: 'coreCookie',
  ctest: 'ctest',
  ctoBundle: 'cto_bundle',
  customerToken: 'ct',
  deleteEmail: 'delete-email',
  didomi_token: 'didomi_token',
  docc: 'docc',
  dynamicPrice: 'dp',
  emailOptOut: 'emailoptout',
  euconsent: 'euconsent',
  euconsent_v2: 'euconsent-v2',
  expressAuthToken: 'express-auth-token',
  expressCheckoutEmail: 'express-email',
  expressRegister: 'express-register',
  fbc: 'fbclid',
  freshRel: 'freshRel',
  gclid: 'dod-gclid',
  giftPackDeliveryTest: 'ndd-test',
  giftPackPrice: 'gpdpi',
  googleLocation: 'google-location',
  googleOneTap: 'google-one-tap',
  googleTimer: 'google-timer',
  hasPassword: 'hp',
  historicPurchaseSource: 'dod-historic-purchase-source',
  landingPage: 'landing_page2',
  lbSessionSkip: 'dod-subscribe-modal-session-skip',
  lightbox: 'subscribeLightbox',
  lightboxClosed: 'offline_lightbox-closed',
  lightboxLocation: 'lightbox-location',
  lightboxVip: 'lightbox_vip',
  lightPage: 'lightPage',
  location: 'dod-location',
  locationPage: 'location-page',
  locationUpdated: 'location-updated',
  loginSourcePageType: 'login-source',
  loginType: 'login-type',
  logoutURL: 'dod-post-logout-url',
  lsIELightBox: 'lsiegslb',
  mapBackPath: 'map-back',
  mapViewTest: 'map-view-test',
  mgid: 'mgid',
  msclkid: 'dod-msclkid',
  n_aft: 'n_aft',
  nibble: 'nibble-test',
  oldDidomiCookieRemoved: 'old_didomi_cookie_removed',
  oneClickCheckout: 'occ',
  onSiteV3: 'onSiteV3',
  optAlertBox: 'OptanonAlertBoxClosed',
  optConsent: 'OptanonConsent',
  pagination: 'pagination',
  passwordlessCheckoutTest: 'pwTest',
  passwordResetToken: 'password_reset_token',
  paypalBilling: 'ppbt',
  postLoginUrl: 'dod-post-login-url',
  profile_image_url: 'dod-user-profile-image-url',
  purchaseUrl: 'dod-purchase-url',
  recentDeals: 'recentDeals',
  redirectLocal: 'dod-redirect-local',
  redirectUrl: 'dod-redirect',
  referralCode: 'referral-code',
  referralRegistered: 'referral-registered',
  registered: 'dod-registered',
  reviewsTest: 'reviews-test',
  sailthruDP: 'dp_st_data_sent',
  sessionId: 'sessionId',
  sessionITOSource: 'dod-ito-source',
  sessionSource: 'dod-session-source',
  showConfirmation: 'show-confirmation',
  smartbannerClosed: 'smartbannerClosed',
  splatDisplay: 'splatDisplay',
  splunkGuid: 'splunkGuid',
  subscribed: 'subscribed',
  subscriptionModal: `${Environment}-subscription-win`,
  temporaryEmail: 'temporary-email',
  tmsID: 'tms_VisitorID',
  unboxedSearchTest: 'unbxd-test',
  userEmail: 'dod-user-email',
  userInfo: 'dod-user-info',
  userLoggedIn: 'dod_logged_in',
  userProfile: 'user_profile',
  userSource: 'dod-user-source',
  v5_ab: 'v5_ab',
  validSubscription: 'valid-subscription',
  videoRolloutTest: 'videoRollout',
  vipChecked: 'vip_checked',
  vipHub: 'viphub',
  visitorId: 'visitor_id',
  vveTestLanding: 'vve-test-landing',
  welcomePage: 'welcome-page-test',
  wishlistId: 'wishlistId',
  wowcherUser: 'wowcher_user',
};

export default cookies;
